<template>
  <div class="h-4/5 relative">
    <sub-header :backButton="true" backRedirectionUrl="/packages" />
    <div class= "m-auto justify-between  h-full px-6 flex-grow">
        <div class="flex h-full gap-1">
            <div class="w-2/12 rounded-lg bg-white shadow-sm h-full p-3 flex flex-col gap-3 overflow-y-scroll scroll-bar">
                <div @click="handleCurrentTab(nav)"  v-for="nav in navigations" :key="nav.nav" class="p-2 transition-all relative duration-200 text-gray-600 items-center hover:text-gray-700 rounded-lg font-medium text-sm flex gap-2  screeing_nav  cursor-pointer" :style="{borderColor: currentTab === nav.nav ? '#cdcdcd': '' }">
                    <font-awesome-icon class="text-gray-500" :icon="[nav.iconPrefix || 'fa', nav.icon]"  /> {{nav.title}}
                    <!-- <font-awesome-icon class="text-gray-500 absolute right-2" v-if="currentTab === nav.nav" icon="arrow-right" /> -->
                </div>
            </div>
            <div :class="subDomainAvailable ? 'w-2/12' : 'hidden'"  class="w-2/12 transition-all ease-out duration-700 bg-gray-100 shadow-md h-full p-3 flex flex-col gap-3 overflow-y-scroll scroll-bar">
                <div class="p-2 transition-all duration-200 font-medium shadow-sm  bg-white rounded-md screeing_nav  cursor-pointer">
                    Description
                </div>
                <div class="p-2 transition-all duration-200 font-medium shadow-sm  bg-white rounded-md screeing_nav  cursor-pointer">
                    Description
                </div>
                <div class="p-2 transition-all duration-200 font-medium shadow-sm  bg-white rounded-md screeing_nav  cursor-pointer">
                    Description
                </div>
            </div>
            <div v-if="currentTab" class="flex-1 bg-white rounded-lg p-3 h-full overflow-y-scroll scroll-bar">
                <KeepAlive>
                  <component  v-bind:is="currentComponent"> </component>

                </KeepAlive>
            </div>
            <div v-else class="flex flex-1 bg-white p-3 h-full overflow-y-scroll scroll-bar justify-center items-center">
                No Options Selected
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import SubHeader from "@/components/SubHeader";
import Button from "@/components/button";
// End
const Description = () => import("@/components/tenant-packages/description/description");
const Checks = () => import("@/components/tenant-packages/checks");
const CandidateForm = () => import("@/components/tenant-packages/candidate-form-view");
const RefereeForm = () => import("@/components/tenant-packages/referee-form-view");
const Tat = () => import("@/components/tenant-packages/tat/tat");
import { mapActions, mapGetters } from "vuex";

// const Email = () => import("@/components/tenant-packages/email/email");
export default {
  name: "screeing-package-view",
  components: {
    SubHeader,
    "neo-button": Button,
    "description": Description,
    'checks': Checks,
    // "email": Email,
  },
  props: {},
  data: () => ({
    navigations: [
      { title: 'Checks', nav: 'checks', icon: 'circle-check', 'subnav': false, iconPrefix:"far"},
      { title: 'Description', nav: 'description', icon: 'file-lines', 'subnav': false, iconPrefix:"far" },
      { title: 'SLA', nav: 'sla', icon: 'clock', 'subnav': false,},
      { title: 'Client Form', nav: 'client-form', icon: 'user', 'subnav': false,},
      { title: 'Subject Form', nav: 'candidate-form', icon: 'user-friends', 'subnav': false,},
      { title: 'Referee Form', nav: 'referee-form', icon: 'user-friends', 'subnav': false,},
      { title: 'Analyst Form', nav: 'screener-form', icon: 'tv', 'subnav': false,},
      // { title: 'Email', nav: 'email', icon: 'envelope', 'subnav': false,},
      { title: 'Others', nav: 'others', icon: 'user-friends', 'subnav': false,},
    ],
    currentTab: null,
    screeningMenuWrapper: {
      "description": Description,
      "checks": Checks,
      // "email": Email,
      "candidate-form":CandidateForm,
      "referee-form": RefereeForm,
      "sla": Tat,
    },
    componentData: null,
    packageData: null,
    
  }),
  async mounted(){
    if (!this.$store.getters.getTenantId) {
        await this.$store.dispatch('fetchTenantId')
    }
    await this.fetchPackageData(this.$route.params.packageName)
    this.packageData = this.getPackage 
  },
  created() {
      let params = this.$route.params;
          if(params.packagetool){
            this.currentTab = params.packagetool
            this.$route.meta.customCrumbs = [{name:params.packagetool}]
            this.$forceUpdate()
       }else{
          this.currentTab = this.navigations[0].nav
          this.$router.push({name: "Package Tools", params: { packagetool: this.navigations[0].nav } });  
          this.$route.meta.customCrumbs = [{name: this.currentTab }]    
          this.$forceUpdate()
       }
       
  },
  computed: {
    ...mapGetters(["getPackage"]),
    subDomainAvailable(){
      let selectedTab = this.currentTab
      if(selectedTab){
          let currentNav = this.navigations.find((ele) => ele.nav === selectedTab)
          return currentNav.subnav
      }else{
        return ''
      }

    },
    currentComponent() {
      return this.screeningMenuWrapper[this.currentTab]
    },
  },

  methods: {
    ...mapActions(["fetchPackageData"]),
    handleCurrentTab(tab){
      if(this.currentTab === tab.nav) return 
      this.currentTab = tab.nav  
      this.setBredCrumbs()
      this.$router.push({name: "Package Tools", params: { packagetool: this.currentTab } });

    },
    setBredCrumbs(){
        this.$route.meta.customCrumbs = [{name:this.currentTab}]
    },
  },
};
</script>
<style scoped>
  .screeing_nav{
    border: 1px solid transparent;
  }
  .screeing_nav:hover{
    border: 1px solid #cdcdcd;
  }
</style>